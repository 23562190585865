<!-- 在线广播 中间部分 -->
<template>
  <div>
    <a-row type="flex" :gutter="20" class="text-center list-card-container" style="padding:25px 0;justify-content:center" v-if="dataList && dataList.length">
      <!-- background:rgba(0,0,0,0.2) -->
      <a-col
        :span="6"
        v-for="(item, key) in dataList"
        :key="key"
        class="m-t-16"
        :rowKey="
          (record, index) => {
            return index
          }
        "
      >
        <a-row class="list-card-bg">
          <a-row class="list-wrap">
            <!-- 对讲任务 -->
            <a-col v-if="item.type === 1">
              <a-row class="list-item">
                <span>{{ item.name }}</span>
              </a-row>
              <a-row class="list-item" v-if="item.broadcastList && item.broadcastList.length">
                <span class="key">设备名称：</span>
                <span class="val">
                  <span v-for="(ite, ind) in item.broadcastList" :key="ind">{{ ite.deviceName }}、</span>
                </span>
              </a-row>
              <a-row class="list-item" v-if="item.areaList && item.areaList.length">
                <span class="key">设备名称：</span>
                <span class="val">
                  <text v-for="(ite, ind) in item.areaList" :key="ind">{{ ite.deviceName }}、</text>
                </span>
              </a-row>
              <a-row class="list-item" v-if="item.status === 1">
                <span class="key">对讲时间：</span>
                <span class="val">
                  <Timer :second="item.second ? item.second + '' : '0'" :type="1"></Timer>
                </span>
              </a-row>
              <a-row class="list-item">
                <p></p>
              </a-row>

              <a-row class="list-item">
                <p></p>
              </a-row>
              <a-row class="middleButton">
                <!-- <a-button size="small">暂停对讲</a-button> -->
                <a-button size="small" @click="endVoiceTask(key, 2)">取消对讲</a-button>
                <a-button size="small" @click="quietVoiceTask(key, 2)">设备禁言</a-button>
                <a-button size="small" @click="showVoiceDevice(key)">添加对讲</a-button>
              </a-row>
            </a-col>
            <!-- 播放任务 -->
            <a-col v-else>
              <a-row class="list-item">
                <span style="font-size:18px">{{ item.name }}</span>
              </a-row>
              <a-row class="list-item">
                <span>播放音频：</span>
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>{{ item.audioList.map((it) => it.fileName).join(',') }}</span>
                  </template>
                  <span>{{ item.audioList.map((it) => it.fileName).join(',') }}</span>
                </a-tooltip>
              </a-row>
              <a-row class="list-item">
                <span>播放设备/区域：</span>
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>{{ item.broadcastList.length ? item.broadcastList.map((it) => it.deviceName).join(',') : item.areaList.length ? item.areaList.map((it) => it.name).join(',') : '' }}</span>
                  </template>
                  <span>{{ item.broadcastList.length ? item.broadcastList.map((it) => it.deviceName).join(',') : item.areaList.length ? item.areaList.map((it) => it.name).join(',') : '' }}</span>
                </a-tooltip>
              </a-row>
              <a-row class="list-item">
                <span>播放时间：</span><span>{{ item.startTime ? item.startTime.split(' ')[1] : '' }} - {{ item.endTime ? item.endTime.split(' ')[1] : '' }}</span>
              </a-row>
              <a-row class="list-item">
                <span>循环方式：</span><span>{{ item.dayCycleFlag === 1 ? '每日循环' : '单次循环' }}</span>
              </a-row>
              <a-row class="list-item">
                <span>计划状态：</span><span>{{ item.status === 1 ? '进行中' : '未开始' }}</span>
              </a-row>
              <a-row class="middleButton">
                <div v-if="item.status === 1">
                  <a-button size="small" @click="endVoiceTask(key, 2)">停止播放</a-button>
                </div>
                <div v-else>
                  <a-button size="small" v-if="item.dayCycleFlag === 1" @click="setTaskEnable(item.id, item.enableStatus === 1 ? 0 : 1)">{{
                    `${item.enableStatus === 1 ? '暂停' : '开启'}计划`
                  }}</a-button>
                  <a-button size="small" @click="removeTask(item.id)">删除计划</a-button>
                </div>
                <!-- <a-button size="small" @click="paseVoiceTask(key,2)">暂停播放</a-button> -->
                <!-- <a-button size="small" @click="endVoiceTask(key, 2)">停止播放</a-button> -->
                <!-- <a-button size="small" @click="paseVoiceTask(key,1)">继续播放</a-button> -->
                <!-- <a-button size="small" @click="showAudioDevice(key)">踢除设备</a-button> -->
                <!-- <a-button size="small" @click="showAudioDevice(key)">静音设备</a-button> -->
                <!-- <a-button size="small"  @click="showAudioDevice(key)">取消静音</a-button> -->
              </a-row>
            </a-col>
          </a-row>
          <!-- <a-row class="list-status">
            {{ item.status === 1 ? '进行中' : '暂停' }}
          </a-row> -->
        </a-row>
      </a-col>
    </a-row>

    <!-- 添加设备 -->
    <a-modal centered width="800px" class="modal-container" :title="addModal.title" v-model="addModal.visible" :footer="false" :destroyOnClose="true" @cancel="cancelAdd">
      <section class="list-table-container">
        <!-- <p>请选择要播放的设备</p> -->
        <a-table
          size="small"
          :columns="deviceColumns"
          :data-source="itemList"
          :scroll="{ y: 360 }"
          :pagination="false"
          :row-selection="{ selectedRowKeys: selectedDeviceRow, onChange: onSelectDeviceChange }"
        ></a-table>
      </section>
      <a-form :form="form" layout="inline" class="m-t-18">
        <a-row class="form-btn-group">
          <a-button v-if="addModal.title == '添加设备'" class="btn-save" @click="addVoiceDevice(taskId)"> <a-icon type="save" />保存 </a-button>
          <a-button v-else @click="outVoiceDevice(taskId)">保存</a-button>
          <a-button class="btn-cancel" @click="cancelAdd">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import Timer from './timer.vue'
  const deviceColumns = [
    { title: '序号', dataIndex: 'ind', align: 'center', key: 'ind' },
    { title: '设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName' }
  ]
  export default {
    name: 'middle',
    props: {
      playDataSource: {
        type: Array,
        default: () => []
      }
    },
    components: { Timer },
    data() {
      return {
        deviceColumns,
        addModal: {
          visible: false,
          title: '添加设备'
        },
        form: {},
        deviceList: [],
        itemList: [],
        selectedDeviceRow: [], //选中行
        unselectedDeviceRow: [], //未选中行
        taskId: '',
        dataList: []
      }
    },
    beforeCreate() {
      this.form = this.$form.createForm(this)
    },
    created() {
      this.dataList = this.playDataSource
      //   this.dataList = [
      //     {
      //       type: 2,
      //       second: 60,
      //       id: 1,
      //       name: '设备名称1'
      //     },
      //     {
      //       type: 2,
      //       second: 60,
      //       id: 2,
      //       name: '设备名称2'
      //     },
      //     {
      //       type: 2,
      //       second: 60,
      //       id: 3,
      //       name: '设备名称3'
      //     }
      //   ]

      this.getDeviceList()
    },
    mounted() {},
    watch: {
      playDataSource: {
        handler() {
          this.dataList = this.playDataSource
        }
      }
    },
    methods: {
      checkTime(key) {
        //   console.log('key', key);
        //   if (this.dataList[key].times && this.dataList[key].times >= 1) {
        //     console.log(1111111);
        //     this.dataList[key].times--;
        //     console.log('this.dataList', this.dataList);
        //   }
        //   else {

        this.endVoiceTask(key, 2)
        //   }
      },
      refresh() {
        this.$emit('change', 1)
      },
      cancelAdd() {
        this.addModal.visible = false
      },
      //获取设备列表
      getDeviceList() {
        this.deviceList = []
        this.$api.getByAreaId().then((res) => {
          if (res.code == 200) {
            let bufferList = []
            if (res.data && res.data.length) {
              res.data.forEach((v, index) => {
                let i = new Object()
                i.ind = index + 1
                i.areaName = v.areaName
                i.areaId = v.areaId
                i.deviceName = v.deviceName
                i.deviceNo = v.deviceNo
                i.id = v.id
                i.status = false
                bufferList.push(i)
              })
            }
            this.deviceList = bufferList
            this.itemList = bufferList
            //   console.log('deviceList', this.deviceList);
          }
        })
      },
      //踢除设备
      outVoiceDevice(id) {
        console.log(id)
        let params = {
          id: id,
          broadcastIds: []
        }
        let unparams = {
          id: id,
          broadcastIds: []
        }
        this.selectedDeviceRow.forEach((v) => {
          params.broadcastIds.push(this.itemList[v].id)
        })
        this.unselectedDeviceRow.forEach((v) => {
          unparams.broadcastIds.push(this.itemList[v].id)
        })
        console.log('params', params)
        console.log('unparams', unparams)
        if (params.broadcastIds && params.broadcastIds.length) {
          this.$api.broadcastMute(params).then((res) => {
            if (res.code == 200) {
              if (unparams.broadcastIds && unparams.broadcastIds.length) {
                this.$api.broadcastMuteRelease(unparams).then((res) => {
                  if (res.code == 200) {
                    this.$message.success('操作成功')
                    this.addModal.visible = false
                    this.refresh()
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$api.broadcastMuteRelease(unparams).then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.addModal.visible = false
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      onSelectDeviceChange(selectedRowKeys) {
        this.unselectedDeviceRow = []
        this.selectedDeviceRow = selectedRowKeys
        this.itemList.forEach((v, index) => {
          if (!selectedRowKeys.includes(index)) {
            this.unselectedDeviceRow.push(index)
          }
        })
        console.log('select', this.selectedDeviceRow)
        console.log('unselect', this.unselectedDeviceRow)
        //   this.playLists = rows
      },
      addVoiceDevice(id) {
        let params = {
          id: id,
          broadcastIds: []
        }
        this.selectedDeviceRow.forEach((v) => {
          params.broadcastIds.push(this.itemList[v].id)
        })
        //   console.log(params);
        this.$api.addVoiceDevice(params).then((res) => {
          if (res.code == 200) {
            this.$message.success('添加成功')
            this.refresh()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      removeTask(id) {
        this.$api
          .removeTask({
            id
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
      },
      setTaskEnable(id, enableStatus) {
        this.$api
          .setTaskEnable({
            id,
            enableStatus: enableStatus
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
      },
      //停止当前广播任务
      endVoiceTask(key, type) {
        //停止设备列表、区域列表中的广播任务
        if (type == 1) {
          let params = {
            id: this.dataSource[key].taskId
          }
          this.$api.endVoiceTask(params).then((res) => {
            if (res.code == 200) {
              this.$message.success('取消成功')
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
        //中间展示部分的停止功能
        else {
          let params = {
            id: this.dataList[key].id
          }
          this.$api.endVoiceTask(params).then((res) => {
            if (res.code == 200) {
              this.$message.success('停止成功')
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },

      //对广播任务禁言
      quietVoiceTask(key, type) {
        console.log(key)
        console.log(type)
        //   下面设备、区域板块禁言
        if (type === 1) {
          console.log('1111')
        }
        //中间板块设备禁言
        else {
          console.log(22222222)
          let params = {
            id: this.dataList[key].id,
            broadcastIds: []
          }
          if (this.dataList[key].broadcastList && this.dataList[key].broadcastList.length) {
            this.dataList[key].broadcastList.forEach((v) => {
              params.broadcastIds.push(v.id)
            })
          }
          this.$api.quietVoiceTask(params).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.refresh()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },

      //暂停or继续广播任务
      paseVoiceTask(key, type) {
        let params = {
          id: this.dataList[key].id,
          remainSeconds: '',
          status: type
        }
        if (type === 1) {
          params.remainSeconds = this.dataList[key].remainSeconds
        } else {
          if (key === 0) params.remainSeconds = parseInt(this.dTimeObj.hour) * 3600 + parseInt(this.dTimeObj.minute) * 60 + parseInt(this.dTimeObj.second)
          else if (key === 1) params.remainSeconds = parseInt(this.dTimeObj1.hour) * 3600 + parseInt(this.dTimeObj1.minute) * 60 + parseInt(this.dTimeObj1.second)
          else if (key === 2) params.remainSeconds = parseInt(this.dTimeObj2.hour) * 3600 + parseInt(this.dTimeObj2.minute) * 60 + parseInt(this.dTimeObj2.second)
          else params.remainSeconds = parseInt(this.dTimeObj3.hour) * 3600 + parseInt(this.dTimeObj3.minute) * 60 + parseInt(this.dTimeObj3.second)
        }
        this.$api.pauseVoiceTask(params).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.$message.success('操作成功')
            this.refresh()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      //打开静音弹窗-默认勾选中已静音的
      showAudioDevice(key) {
        this.itemList = []
        console.log(this.dataList)
        this.selectedDeviceRow = []
        this.dataList[key] &&
          this.dataList[key].broadcastList &&
          this.dataList[key].broadcastList.length &&
          this.dataList[key].broadcastList.forEach((v, index) => {
            this.itemList.push({
              id: v.id,
              ind: index + 1,
              deviceName: v.deviceName
            })
            if (v.muteStatus === 1) this.selectedDeviceRow.push(index)
          })
        console.log('selectCreate', this.selectedDeviceRow)
        this.addModal.visible = true
        this.taskId = this.dataList[key].id
        this.addModal.title = '静音设备'
      },
      //打开添加对讲弹窗
      showVoiceDevice(key) {
        this.itemList = this.deviceList
        console.log(this.dataList)
        this.dataList[key] &&
          this.dataList[key].broadcastList &&
          this.dataList[key].broadcastList.length &&
          this.dataList[key].broadcastList.forEach((v) => {
            this.deviceList.forEach((el, index) => {
              if (v.id == el.id) {
                this.selectedDeviceRow.push(index)
              }
            })
          })
        console.log('selectCreate', this.selectedDeviceRow)
        this.addModal.visible = true
        this.taskId = this.dataList[key].id
      }
    }
  }
</script>
<style lang="scss" scoped>
  .middleButton {
    button {
      background: #3f72ba;
      color: #c9dfff;
      border-radius: 2px;
      font-size: 13px;
      border: 1px solid #3f72ba;
      min-width: 66px;

      margin-right: 10px;
      // &:first-child {
      //   margin-right: 10px;
      // }
    }
  }
</style>
