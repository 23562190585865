<template>
  <section class="info-container">
    <section class="info-item-wrap">
      <p class="info-title">在线广播</p>
      <a-row style="padding:50px 0;background:rgba(0,0,0,0.2)">
        <div class="text-center">
          <span style="font-size:18px;color:#1890ff">
            <a-icon class="m-r-10" type="notification" />
          </span>
          <span
            style="font-size:16px;color:rgba(255,255,255,0.6)"
          >{{broadcastDeviceState === -1 ? '暂无广播设备': broadcastDeviceState === 0 ?'广播设备已离线' : broadcastDeviceState === 1 ? '广播系统连接正常' : '广播系统连接故障'}}</span>
        </div>
      </a-row>
    </section>

    <section class="info-item-wrap m-t-18">
      <p class="info-title"></p>
      <!-- new -->
      <!-- 中间卡片展示 -->
      <Middle :playDataSource="playDataSource" @change="refreshMiddleData"></Middle>
      <section
        class="nav-container m-t-18"
        style="width:160px;background-color:#3f72ba;text-align:center; "
      >
        <a
          @click="navActiveKey = item.key,getData()"
          :class="item.key == navActiveKey ? 'active' : ''"
          style="margin-left:30px"
          v-for="item in navMap"
          :key="item.key"
        >{{item.name}}</a>
      </section>
      <section v-if="navActiveKey == 1">
        <VoiceIntercom
          :playDataSource="playDataSource"
          :navActiveKey="navActiveKey"
          @change="refreshMiddleData"
        ></VoiceIntercom>
      </section>
      <section v-if="navActiveKey == 2">
        <AutoPlay :playDataSource="playDataSource" @change="refreshMiddleData"></AutoPlay>
      </section>
    </section>
  </section>
</template>

<script>
  let timer = null

import Middle from './middle.vue'
import VoiceIntercom from './voiceIntercom.vue'
import AutoPlay from './autoPlay.vue'
export default {
  data() {
    return {
      playDataSource: [
        { name: "对讲分组1", deviceName: '设备名称', areaName: '一号区域', taskId: '1231231321321', type: 1 },
        { name: "对讲分组2", deviceName: '设备名称', areaName: '一号区域', type: 1 },
        { name: "对讲分组3", deviceName: '设备名称', areaName: '一号区域', type: 1 },
        { name: "对讲分组4", deviceName: '设备名称', areaName: '一号区域', type: 1 }

      ],
      broadcastDeviceState: -1,  //广播设备状态
      //new
      dataSource: [],
      //导航栏
      navMap: [
        // { name: '语音对讲', key: 1 },
        { name: '自动轮播', key: 2 }
      ],
      //控制导航栏跳转
      navActiveKey: 2,
      navActiveKey1: 1,
      //分页
      current: 1,
      total: 0,
      size: 10,
      //选中时的icon
      iconUrl: require('@/assets/image/icon/checked.png'),
      intercomCount: 0,
      audioCount: 0,
      muteStatus: 0,//用于判断静音状态
    }
  },
  components: {
    Middle,
    VoiceIntercom, AutoPlay
  },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  created() {
    this.getVoiceTaskList();
    this.queryBroadcastDeviceState()//广播设备状态
    this.queryCurPlayStatus()//当前广播播放状态
    // this.getData();//根据导航栏key获取相应dataSource
    //this.tasker()
    timer = setInterval(() => {
        this.getVoiceTaskList();
        this.queryBroadcastDeviceState()//广播设备状态
        this.queryCurPlayStatus()//当前广播播放状态
    }, 10000)
  },
  destroyed() {
    clearInterval(timer)
  },
  methods: {
    //中间部分刷新数据
    refreshMiddleData(index) {
      if (index === 1) {
        this.getVoiceTaskList();
        this.queryBroadcastDeviceState()//广播设备状态
        this.queryCurPlayStatus()//当前广播播放状态
      }
    },
    timeOnChange(e) {
      console.log(e);
    },
    checkChange(e) {
      console.log('e', e);
    },


    //全选
    selectAll() {
      this.dataSource.forEach(v => {
        v.isSelect = true;
      })
    },
    tasker() {
      this.timer = setInterval(() => {
        this.queryCurPlayStatus()
      }, 1000);
    },
    //根据natviceKey获取对应数据
    getData() {
      this.getVoiceTaskList();
    },
    //查询当前未结束的广播任务
    getVoiceTaskList() {
    //   this.playDataSource = [];
      this.$api.getVoiceTaskList().then(res => {
        if (res.code == 200) {
          if (res.data && res.data.records) {
            this.playDataSource = res.data.records;
            this.playDataSource.forEach(v => {
              if (v.type === 1) {
                if (v.broadcastList.length || v.areaList.length) {
                  this.count++;
                  v.name = '对讲分组' + this.intercomCount;

                }
              }
              else {
                this.audioCount++;
                // v.name = '播放任务' + this.audioCount;
                // v.remainSeconds = 200;
              }
              if (v.startTime && v.times) {
                let data = this.changeTime(v.startTime);
                let nowTime = Date.parse(new Date()) / 1000;
                // console.log('123', v.minutes * 60 * v.times - this.intervalTime(data, nowTime) > 0);
                let rest = v.minutes * 60 * v.times - this.intervalTime(data, nowTime) > 0 ? v.minutes * 60 * v.times - this.intervalTime(data, nowTime) : '0';
                v.second = Number(rest) + '';
              }
              else if (v.startTime && !v.times) {
                let data = this.changeTime(v.startTime);
                let nowTime = Date.parse(new Date()) / 1000;
                let rest = v.minutes * 60 - this.intervalTime(data, nowTime) > 0 ? v.minutes * 60 - this.intervalTime(data, nowTime) : '0';
                v.second = Number(rest) + '';
              }
              else {
                v.second = '0'
              }
            })
            this.playDataSource.forEach(v => {
              if (v.status === 1 && v.remainSeconds) {
                v.second = v.remainSeconds + '';
              }
            })
          }
        }
      })
    },


    //广播设备状态
    queryBroadcastDeviceState() {
      this.$api.queryBroadcastDeviceState().then(res => {
        if (res.code === 200) {
          let _data = res.data || {}
          this.broadcastDeviceState = _data.state
        }
      })
    },
    //当前广播播放状态
    queryCurPlayStatus() {
      this.$api.queryCurPlayStatus().then(res => {
        if (res.code == 200) {
          let _data = res.data || {}
          this.playStatus = _data.status
          if (this.playStatus === 1) {

            this.totalTimeLength = _data.totalDuration - _data.playProgress
          }
        }
      })
    },

    //格式化时分秒 00:00:00
    filterDate(v) {
      return parseFloat(v) < 10 ? '0' + v : v
    },
    toDub(n) {//补0操作
      if (n < 10) return '0' + n
      else return '' + n
    },

    //YYYY-MM-DD HH:MM:SS转化为时间错
    changeTime(time) {
      let timestamp = Date.parse(new Date(time));
      timestamp = timestamp / 1000;
      return timestamp
    },
    //计算两个时间之间的时间差 多少秒
    intervalTime(startTime, endTime) {
      let date1 = startTime
      let date2 = endTime; //结束时间
      let date3 = (date2 - date1); //时间差的毫秒数
      return date3;
    }
    //--------------------------------------------
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.$notification.destroy()
  },
}
</script>
<style lang="scss" scoped>
.rowClass {
  margin-top: 3px;
}

// 必填星号
.redstar {
  position: relative;
  box-sizing: border-box;
  padding-right: 10px;

  &:after {
    display: block;
    content: "*";
    position: absolute;
    width: 7px;
    height: 14px;
    color: #f5222d;
    font-size: 20px;
    top: 5px;
    left: -5px;
  }
}
</style>