<!-- 语音对讲 -->
<template>
  <div>
    <section class="nav-container1 m-t-18">
      <a
        @click="navActiveKey1 = item.key,getData()"
        :class="item.key == navActiveKey1 ? 'active' : ''"
        style="margin-right:5px"
        v-for="item in navMap"
        :key="item.key"
      >{{item.name}}</a>
    </section>
    <section v-if="navActiveKey == 1&&navActiveKey1 ==1">
      <a-row :gutter="20" class="m-t-16 m-b-16">
        <a-button type="primary" size="small" class="m-r-10 m-l-16" @click="selectAll">全选</a-button>
        <a-button type="primary" size="small" class="m-r-10" @click="addIntercom()">多设备对讲</a-button>
        <!-- <a-button type="primary" size="small" class="m-r-10">多设备禁言</a-button> -->
      </a-row>
      <!-- 语音对讲-设备播报 -->
      <a-row
        type="flex"
        :gutter="20"
        class="list-card-container"
        v-if="dataSource&&dataSource.length"
      >
        <!-- <a-checkbox-group style="width:100%" @change="checkChange"> -->
        <a-col
          :span="6"
          v-for="(item,key) in dataSource"
          :key="key"
          class="m-t-16"
          @click="clickOn(key)"
        >
          <a-row class="list-card-bg">
            <a-row class="list-wrap">
              <!-- <a-checkbox class="list-checkBox" :value="item.id" :checked="item.status"></a-checkbox> -->
              <a-col>
                <a-row class="list-item list-title">
                  <span>{{item.deviceName}}</span>
                </a-row>
                <a-row class="list-item">
                  <span class="key">所属区域：</span>
                  <span class="val">{{item.areaName}}</span>
                </a-row>
                <a-row class="list-item">
                  <p></p>
                </a-row>
                <a-row class="list-item">
                  <p></p>
                </a-row>
                <a-row class="list-btn-group-position" style="right:2px">
                  <a-button
                    v-if="!item.taskId"
                    size="small"
                    @click="((e)=>{Intercom(e,item.ind)})"
                  >对讲</a-button>

                  <a-button
                    v-if="item.taskId"
                    size="small"
                    @click="((e)=>{endIntercom(e,item.ind,1)})"
                  >取消对讲</a-button>
                  <a-button
                    v-if="item.taskId&&item.taskType == 2"
                    size="small"
                    @click="((e)=>{quietIntercom(e,item.ind,1)})"
                  >禁言</a-button>
                </a-row>
              </a-col>
            </a-row>
            <a-row v-if="item.taskId!=''" class="list-status">
              <!-- {{item.approveStatus === '-1' ? '已撤回' : item.approveStatus === '1' ? '已同意' : item.approveStatus === '2' ? '已拒绝' : '已禁言'}} -->
              {{item.taskStatus == '1' ? '进行中' : '暂停'}}
            </a-row>
          </a-row>
          <a-row class="clickOK" v-if="item.isSelect">
            <a-col :span="10" style="font-size:36px;text-align:right">
              <a-icon type="check-circle" />
            </a-col>
            <a-col :span="12" style="text-align:left;padding-left:5px">
              <a-row>已选择设备播放</a-row>
              <a-row style="color:#ccccc0;font-size:12px">在次点击取消选择</a-row>
            </a-col>
          </a-row>
        </a-col>
        <!-- </a-checkbox-group> -->
      </a-row>
    </section>
    <section v-else-if="navActiveKey == 1&&navActiveKey1 ==2">
      <!-- 语音对讲-区域播报 -->
      <a-row type="flex" :gutter="20" class="list-card-container" v-if="dataSource.length">
        <a-col :span="6" v-for="(item,key) in dataSource" :key="key" class="m-t-16">
          <!-- <a-checkbox class="list-checkBox"></a-checkbox> -->
          <a-row class="list-card-bg">
            <a-row class="list-wrap">
              <a-col>
                <a-row class="list-item list-title">
                  <span>{{item.name}}</span>
                </a-row>
                <a-row class="list-item">
                  <span class="key">关联设备：</span>
                  <template v-if="item.broadcastList&&item.broadcastList.length">
                    <span
                      class="val"
                      v-for="(ite,ind) in item.broadcastList"
                      :key="ind"
                    >{{ite.deviceName}}、</span>
                  </template>
                  <span class="val" v-else>暂无设备</span>
                </a-row>
                <a-row class="list-item">
                  <p></p>
                </a-row>
                <a-row class="list-item">
                  <p></p>
                </a-row>
                <a-row class="list-btn-group-position" style="right:2px">
                  <a-button size="small" v-if="!item.taskId" @click="areaIntercom(key)">对讲</a-button>

                  <a-button v-if="item.taskId" size="small" @click="endVoiceTask(key,1)">取消对讲</a-button>

                  <a-button
                    v-if="item.taskId&&item.taskType == 2"
                    size="small"
                    @click="quietVoiceTask(key,1)"
                  >禁言</a-button>
                </a-row>
              </a-col>
            </a-row>
            <!-- <a-row
              class="list-status"
            >{{item.approveStatus === '-1' ? '已撤回' : item.approveStatus === '1' ? '已同意' : item.approveStatus === '2' ? '已拒绝' : '待审批'}}</a-row>-->
          </a-row>
        </a-col>
      </a-row>
    </section>
    <section
      class="pagination-container"
      v-if="(navActiveKey==1&&navActiveKey1!=2)||(navActiveKey == 2)"
    >
      <a-pagination
        show-quick-jumper
        show-size-changer
        :current="current"
        :total="total"
        :show-total="(total) => `共${total}条`"
        @change="onCurrentChange"
        @showSizeChange="onShowSizeChange"
      />
    </section>
  </div>
</template>

<script>
//导航栏
const navMap = [
  { name: '设备播报', key: 1 },
  { name: '区域播报', key: 2 }
]

export default {
  name: 'voiceIntercom',
  props: {
    navActiveKey: {
      type: Number,
      default: 1
    },
    playDataSource: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      navMap,
      navActiveKey1: 1,
      //存放数据
      dataSource: [
        {
          ind: 1, status: 1, isSelect: false, areaName: '123', deviceName: '测试', deviceNo: '111', id: '123123', type: 1
        }
      ],
      //分页
      current: 1,
      size: 10,
      total: 0
    }
  },
  created() {
    this.getData();
  },
  mounted() {

  },
  methods: {
    refresh() {
      this.$emit('change', 1)
    },
    //全选
    selectAll() {
      this.dataSource.forEach(v => {
        v.isSelect = true;
      })
    },
    //选中播放设备或播放区域
    clickOn(index) {
      console.log(index);
      console.log(this.dataSource[index]);
      //   if (this.dataSource[index].isSelect == 0) this.dataSource[index].isSelect = 1;
      //   else this.dataSource[index].isSelect = 0;
      this.dataSource[index].isSelect = !this.dataSource[index].isSelect;
    },
    //根据natviceKey获取对应数据
    getData() {
      if (this.navActiveKey1 == 1) {
        this.getBroadcastPage();//分页查询广播设备列表
      }
      else {
        this.getBroadcastByArea();//分页查询区域及其广播列表
      }
    },

    //区域开启对讲
    areaIntercom(index) {
      if (this.playDataSource && this.playDataSource.length === 4) {
        this.$message.error('当前播放数量达到上限');
        return;
      }
      let params = {
        areaIds: [this.dataSource[index].id],
        type: 1
      }
      this.$api.addVoiceTask(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('提交成功')
          //   this.getVoiceTaskList();
          this.refresh();
          this.getBroadcastByArea();
        }
        else {
          this.$message.error(res.msg)
        }
      })
    },
    //设备开启对讲任务
    addIntercom(key) {
      if (this.playDataSource && this.playDataSource.length === 4) {
        this.$message.error('当前播放数量达到上限');
        return;
      }
      if (key) {

        let params = {
          broadcastIds: [this.dataSource[key - 1].id],
          areaIds: [this.dataSource[key - 1].id],
          type: 1
        }
        if (this.dataSource[key - 1].type == 1) delete params.areaIds;
        else delete params.deviceIds;
        console.log('params', params);
        this.$api.addVoiceTask(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            //   console.log();
            this.$message.success('提交成功');
            this.refresh();
            // this.getVoiceTaskList();
          }
          else {
            this.$message.error(res.msg)
          }
        })
      }
      else {
        let params = {
          broadcastIds: [],
          type: 1
        }
        let bufferList = [];
        this.dataSource.forEach(v => {
          if (v.isSelect) bufferList.push(v.id)
        })
        console.log(bufferList);
        params.broadcastIds = bufferList;
        this.$api.addVoiceTask(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success('提交成功')
            // this.getVoiceTaskList();
            this.refresh();
            this.getBroadcastPage();
          }
          else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    //对广播任务禁言
    quietVoiceTask(key, type) {
      console.log(key);
      console.log(type);
      //   下面设备、区域板块禁言
      if (type === 1) {
        console.log('1111');
      }
      //中间板块设备禁言
      else {
        console.log(22222222);
        let params = {
          id: this.playDataSource[key].id,
          broadcastIds: []
        }
        if (this.playDataSource[key].broadcastList && this.playDataSource[key].broadcastList.length) {
          this.playDataSource[key].broadcastList.forEach(v => {
            params.broadcastIds.push(v.id)
          })
        }
        this.$api.quietVoiceTask(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.refresh();
            this.$message.success('操作成功')
            // this.getVoiceTaskList();
          }
          else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    //停止当前广播任务
    endVoiceTask(key, type) {
      console.log(key);
      console.log(type);
      //停止设备列表、区域列表中的广播任务
      if (type == 1) {
        let params = {
          id: this.dataSource[key].taskId
        }
        this.$api.endVoiceTask(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success('取消成功')
            this.getData();
            this.refresh();
          }
          else {
            this.$message.error(res.msg)
          }
        })
      }
      //中间展示部分的停止功能
      else {
        let params = {
          id: this.playDataSource[key].id
        }
        this.$api.endVoiceTask(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success('取消成功');
            this.getData();
            this.refresh();
          }
          else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    //分页查询广播设备列表
    getBroadcastPage() {
      this.dataSource = [];
      let params = {
        current: this.current,
        size: this.size,
      }
      this.$api.getBroadcastPage(params).then(res => {
        if (res.code === 200) {
          if (res.data && res.data.records) {
            let bufferList = [];
            res.data.records.forEach((v, ind) => {
              let i = new Object();
              i.ind = ind + 1
              i._status = v.status ? '已接入' : '未接入';
              i.isSelect = false;
              i.areaId = v.areaId;
              i.areaName = v.areaName;
              i.deviceName = v.deviceName;
              i.deviceNo = v.deviceNo;
              i.id = v.id;
              i.status = v.status;
              i.type = 1;
              i.taskId = i.taskId ? i.taskId : '';
              //   i.taskId = '1231';
              bufferList.push(i);
            })
            this.dataSource = bufferList;
          }
          //   this.dataSource = (res.data && res.data.records) || []
          //   this.dataSource.forEach((item, ind) => {
          //     item.ind = ind + 1
          //     item._status = item.status ? '已接入' : '未接入';
          //   })
          this.total = (res.data && res.data.total) || 0
          this.current = (res.data && res.data.current) || 1

        }
      })
    },

    //分页查询区域及其广播列表
    getBroadcastByArea() {
      this.dataSource = [];
      let params = {
        current: this.current,
        size: this.size,
      }
      this.$api.getBroadcastByArea(params).then(res => {
        if (res.code === 200) {
          let bufferList = [];
          if (res.data && res.data.length) {
            res.data.forEach((v, ind) => {
              let i = new Object();
              i.ind = ind + 1
              i.isSelect = false;
              i.id = v.id;
              i.name = v.name;
              i.broadcastList = v.broadcastList;
              i.type = 2;
              bufferList.push(i);
            })
          }
          this.dataSource = bufferList;
          //   this.dataSource = res.data ? res.data : [];
          //   this.total = (res.data && res.data.total) || 0
          //   this.current = (res.data && res.data.current) || 1

        }
      })
    },
    //阻止冒泡
    Intercom(e, key) {
      console.log('key', key);
      this.addIntercom(key);
      e.stopPropagation();
    },
    endIntercom(e, key, type) {
      this.endVoiceTask(key, type)
      e.stopPropagation();
    },
    quietIntercom(e, key, type) {
      this.quietVoiceTask(key, type)
      e.stopPropagation();
    },
    //分页相关

    onCurrentChange(page, pageSize) {
      this.current = page;
      this.size = pageSize
      this.getData();
    },
    onShowSizeChange(current, size) {
      this.current = current;
      this.size = size;
      this.getData();
    },
  }
}
</script>
<style lang="scss" scoped>
.clickOK {
  position: absolute;
  top: 0;
  display: inline-block;
  height: 100%;
  width: calc(100% - 16px);
  box-sizing: border-box;
  padding-top: 50px;
  line-height: 24px;
  font-size: 13px;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: #ff9933;
  user-select: none;
}
</style>