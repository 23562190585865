<!-- 自动轮播 -->
<template>
  <div>
    <a-row class="m-t-18">
      <a-button @click="showMultyPlayModal" class="f-right" type="primary">多音频播放</a-button>
      <upload @func="getFile" class="f-right m-r-10 m-b-10" :node="{ type: 2, icon: 'plus', text: '新增音频' }" :accept="['.mp3', '.wav']" />
    </a-row>
    <section class="list-table-container">
      <a-table size="small" :columns="columns" :data-source="listMap" :pagination="false" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot="fileSize" slot-scope="text">{{ text ? (Number(text) / 1000000).toFixed(2) + 'MB' : '-' }}</span>
        <a slot="action" slot-scope="text, list">
          <a class="m-r-10" @click="showAudioModal(list)">试听</a>
          <!-- <a class="m-r-10" @click="play(list)">播放</a> -->
          <a class="m-r-10" @click="showPlayModal(list)">播放</a>
          <a-popconfirm title="确定删除该音频?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
        </a>
      </a-table>
    </section>

    <section class="pagination-container">
      <a-pagination show-quick-jumper show-size-changer :current="current" :total="total" :show-total="(total) => `共${total}条`" @change="onCurrentChange" @showSizeChange="onShowSizeChange" />
    </section>
    <!-- 单音频播放 -->
    <a-modal centered width="800px" class="modal-container" :title="playModal.title" v-model="playModal.visible" :footer="false" :destroyOnClose="true" @cancel="cancelPlay">
      <section class="list-table-container">
        <p>请选择要播放的设备</p>
        <!-- <a-table
          size="small"
          :columns="playColumns"
          :data-source="playLists"
          :pagination="{total,hideOnSinglePage:true}"
        >
          <a slot="action" slot-scope="text,list">
            <a-popconfirm title="确定删除该音频?" @confirm="deleteSelect(list.id)">删除</a-popconfirm>
          </a>
        </a-table>-->
        <a-row type="flex" :gutter="20" class="list-card-container" style="height:425px;overflow-y:auto">
          <!-- <a-checkbox-group style="width:100%" @change="checkChange"> -->
          <a-col :span="12" v-for="(item, key) in deviceList" :key="key" class="m-t-16" @click="clickOnDevice(key)">
            <a-row class="list-card-bg">
              <a-row class="list-wrap">
                <!-- <a-checkbox class="list-checkBox" :value="item.id" :checked="item.status"></a-checkbox> -->
                <a-col>
                  <a-row class="list-item list-title">
                    <span>{{ item.deviceName }}</span>
                  </a-row>
                  <a-row class="list-item">
                    <span class="key">所属区域：</span>
                    <span class="val">{{ item.areaName }}</span>
                  </a-row>
                  <a-row class="list-item">
                    <p></p>
                  </a-row>
                  <a-row class="list-item">
                    <p></p>
                  </a-row>
                  <!-- <a-row class="list-btn-group-position" style="right:2px">
                      <a-button size="small">对讲</a-button>

                      <a-button size="small">禁言</a-button>
                  </a-row>-->
                </a-col>
              </a-row>
            </a-row>
            <a-row class="clickOK" v-if="item.status">
              <a-col :span="10" style="font-size:36px;text-align:right">
                <a-icon type="check-circle" />
              </a-col>
              <a-col :span="12" style="text-align:left;padding-left:5px">
                <a-row>已选择设备播放</a-row>
                <a-row style="color:#ccccc0;font-size:12px">在次点击取消选择</a-row>
              </a-col>
            </a-row>
          </a-col>

          <!-- </a-checkbox-group> -->
        </a-row>
      </section>
      <a-form :form="form" layout="inline" class="m-t-18">
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="playOnceAudio"> <a-icon type="save" />保存 </a-button>
          <a-button class="btn-cancel" @click="cancelPlay">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal centered width="660px" class="modal-container" :title="multyPlayModal.title" v-model="multyPlayModal.visible" :footer="false" :destroyOnClose="true" @cancel="cancelSelect">
      <section class="list-table-container" style="height: 120px; overflow-y: auto;">
        <!-- <a-table
          size="small"
          :columns="playColumns"
          :data-source="playLists"
          :pagination="{total,hideOnSinglePage:true}"
        >
          <a slot="action" slot-scope="text,list">
            <a-popconfirm title="确定删除该音频?" @confirm="deleteSelect(list.id)">删除</a-popconfirm>
          </a>
        </a-table>-->
        <table class="dataTabble">
          <thead>
            <tr>
              <th width="110">序号</th>
              <th width="300">音频名称</th>
              <th width="170">操作</th>
            </tr>
          </thead>
          <template v-if="playLists && playLists.length">
            <draggable v-model="playLists" element="tbody" :move="getdata" @update="datadragEnd">
              <tr v-for="(item, index) in playLists" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.fileName }}</td>
                <!-- <td>{{item.num}}</td> -->
                <td>
                  <div class="tabopa">
                    <a style="cursor:pointer" @click="dialogFormVisible = true">试听</a>
                    <!-- <a-divider style="color:#222" type="vertical" /> -->
                    &nbsp;
                    <a-popconfirm style="color:#1890ff;cursor:pointer" title="确定删除该音频?" v-if="selectedRowKeys.length > 1" @confirm="deleteSelect(item.id)">删除</a-popconfirm>&nbsp;
                    <a-icon style="color:#40a9ff;cursor:pointer" type="menu" />
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
          <empty v-else></empty>
        </table>
      </section>
      <a-form :form="form" layout="inline" class="m-t-18">
        <a-row class="rowClass">
          <a-form-item label="播放循环" class="redstar">
            <a-radio-group :options="plainOptions" @change="cycleChange" default-value="单次" />
          </a-form-item>
        </a-row>
        <a-row class="rowClass">
          <a-form-item label="任务名称" class="redstar">
            <a-input v-model="taskName" size="small" style="width: 200px" />
          </a-form-item>
        </a-row>
        <!-- <a-row class="rowClass" v-if="isShowCycle">
          <a-form-item label="播放次数" class="redstar">
            <a-input type="number" :min="1" placeholder="请输入" v-model="playNumber" class="form-input" suffix="次"></a-input>
          </a-form-item>
        </a-row> -->
        <!-- <a-row class="rowClass">
          <a-form-item label="播放时间">
            <a-input placeholder="请输入" v-model="endTime" class="form-input" suffix="分钟"></a-input>
          </a-form-item>
        </a-row> -->
        <a-row class="rowClass">
          <a-form-item label="播放方式" class="redstar">
            <a-select placeholder="请选择" v-model="playType" style="width:200px" @change="typeChange">
              <a-select-option value="设备播放">设备播放</a-select-option>
              <a-select-option value="区域播放">区域播放</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row class="rowClass" v-if="isShowDevice">
          <a-form-item label="播放设备" class="redstar">
            <a-select mode="multiple" placeholder="请输入" v-model="device" style="width:200px" optionFilterProp="children">
              <a-select-option v-for="(item, index) in deviceList" :key="index" :value="item.id">{{ item.deviceName }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row class="rowClass" v-if="isShowArea">
          <a-form-item label="播放区域" class="redstar">
            <a-select mode="multiple" placeholder="请输入" v-model="area" style="width:200px" optionFilterProp="children">
              <a-select-option v-for="(item, index) in areaList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row class="rowClass">
          <a-form-item label="定时播放" class="redstar">
            <a-select placeholder="请选择" v-model="timerPlay" style="width:200px">
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="0">否</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row class="rowClass" v-if="timerPlay === '0' && isShowCycle">
          <a-form-item label="循环次数" class="redstar">
            <a-input placeholder="请输入" v-model="playCount" class="form-input" suffix="次"></a-input>
          </a-form-item>
        </a-row>

        <div v-if="timerPlay === '1'">
          <a-row class="rowClass" v-if="isShowCycle">
            <a-form-item label="每日循环" class="redstar">
              <a-select placeholder="请选择" v-model="dailyCycle" style="width:200px">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-row>
          <a-row class="rowClass">
            <a-form-item label="开始日期" class="redstar">
              <a-date-picker style="width:200px" v-model="timerStartDate" />
            </a-form-item>
          </a-row>
          <a-row class="rowClass">
            <a-form-item label="开始时间" class="redstar">
              <a-time-picker style="width:200px" format="HH:mm" v-model="timerStartTime" />
            </a-form-item>
          </a-row>

          <a-row class="rowClass" v-if="isShowCycle && dailyCycle === '1'">
            <a-form-item label="结束时间" class="redstar">
              <a-time-picker style="width:200px" format="HH:mm" v-model="timerEndTime" />
            </a-form-item>
          </a-row>
        </div>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="playAudio"> <a-icon type="save" />保存 </a-button>
          <a-button class="btn-cancel" @click="cancelSelect">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import upload from './upload.vue'
  import empty from '@/components/empty'
  import draggable from 'vuedraggable'
  const audioKey = 'audioKey'
  const columns = [
    { title: '音频名称', dataIndex: 'fileName', align: 'center', key: 'fileName' },
    { title: '音频时长(s)', dataIndex: 'timeLength', align: 'center', key: 'timeLength' },
    { title: '文件大小', dataIndex: 'fileSize', align: 'center', key: 'fileSize', scopedSlots: { customRender: 'fileSize' } },
    { title: '上传时间', dataIndex: 'createTime', align: 'center', key: 'createTime' },
    { title: '上传人', dataIndex: 'createUser', align: 'center', key: 'createUser' },
    { title: '操作', key: 'logImage', scopedSlots: { customRender: 'action' }, align: 'center' }
  ]
  const playColumns = [
    { title: '序号', dataIndex: 'ind', align: 'center', key: 'ind' },
    { title: '音频名称', dataIndex: 'fileName', align: 'center', key: 'fileName' },
    //   { title: '音频时长(s)', dataIndex: 'timeLength', align: 'center', key: 'timeLength' },
    { title: '操作', key: 'logImage', scopedSlots: { customRender: 'action' }, align: 'center' }
  ]
  const plainOptions = [
    //循环列表
    { label: '单次', value: '单次' },
    { label: '循环', value: '循环' }
  ]
  export default {
    name: 'autoPlay',
    props: {
      playDataSource: {
        type: Array,
        default: () => []
      }
    },
    components: {
      upload,
      empty,
      draggable
    },
    data() {
      return {
        columns, //音频列表表头
        playColumns, //批量播放表头
        plainOptions,
        form: {},
        listMap: [], //音频列表
        playLists: [],

        currentPlayItem: {}, //本地播放对象
        selectedRowKeys: [], //选中行
        multyPlayModal: {
          //控制批量播放弹窗
          visible: false,
          title: '多音频播放'
        },
        playModal: {
          //播放弹窗
          visible: false,
          title: '播放设置'
        },

        isShowCycle: false, //是否展示循环次数（新增）
        isShowDevice: false, //是否展示播放设备选择框（新增）

        totalTimeLength: 0, //总时长
        endTime: 0, //播放时间
        isShowArea: false, //是否展示播放区域选择框（新增）

        playCycle: 1, //循环次数
        playNumber: 1, //播放次数
        playType: '', //播放方式
        deviceList: [], //播放设备列表
        areaList: [], //播放区域列表
        device: [], //播放设备
        area: [], //播放区域
        timer: null,
        playStatus: 0,
        //分页
        total: 0,
        current: 1,
        size: 10,

        taskName: '', //任务名称
        timerPlay: null, //定时播放
        timerStartDate: null, //开始日期
        timerStartTime: null, //开始时间
        timerEndTime: null, //结束时间
        playCount: null, //播放次数
        dailyCycle: null, //每日循环

        cachePlainOptions: []
      }
    },
    beforeCreate() {
      this.form = this.$form.createForm(this)
    },
    created() {
      this.getData()
    },
    mounted() {},
    methods: {
      resetForm() {
        this.taskName = null //任务名称
        this.playType = null //播放方式
        this.device = [] //播放设备列表
        this.area = [] //播放区域列表
        this.isShowArea = false //是否展示播放区域选择框
        this.isShowDevice = false //是否展示播放设备选择框
        this.timerPlay = null //定时播放
        this.timerStartDate = null //开始日期
        this.timerStartTime = null //开始时间
        this.timerEndTime = null //结束时间
        this.playCount = null //播放次数
        this.dailyCycle = null //每日循环
      },
      refresh() {
        this.$emit('change', 1)
      },
      getData() {
        this.queryResourceList() //查询音频资源列表
        this.getAreaList() //获取区域下拉框
        this.getDeviceList() //获取设备列表
      },
      //获取设备列表
      getDeviceList() {
        this.deviceList = []
        this.$api.getNoTaskDeviceList().then((res) => {
          if (res.code == 200) {
            let bufferList = []
            if (res.data && res.data.length) {
              res.data.forEach((v, index) => {
                let i = new Object()
                i.ind = index + 1
                i.areaName = v.areaName
                i.areaId = v.areaId
                i.deviceName = v.deviceName
                i.deviceNo = v.deviceNo
                i.id = v.id
                i.status = false
                bufferList.push(i)
              })
            }
            this.deviceList = bufferList
            this.itemList = bufferList
            //   console.log('deviceList', this.deviceList);
          }
        })
      },
      //获取区域列表
      getAreaList() {
        this.$api.getUsableAreaList().then((res) => {
          if (res.code === 200) {
            this.areaList = res.data ? res.data : []
          }
        })
      },
      //查询音频资源列表
      queryResourceList() {
        let params = {
          current: this.current,
          size: this.size
        }
        this.$api.getAudioList(params).then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.listMap = (res.data && res.data.records) || []
            this.listMap.forEach((item, ind) => {
              item.ind = ind + 1
              item.key = item.id
              item.createTime = item.createTime ? moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') : ''
              item.timeLength = item.duration ? parseInt(item.duration) : 0
              item.createUser = item.creator ? item.creator : ''
            })
            this.total = (res.data && res.data.total) || 0

            //  缓存分页数据
            var obj = {}
            this.cachePlainOptions = [...this.cachePlainOptions, ...this.listMap].reduce(function(item, next) {
              obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
              return item
            }, [])
          }
        })
      },

      //监听播放循环
      cycleChange(e) {
        this.resetForm()
        this.playCycle = e.target.value
        if (e.target.value == '单次') this.isShowCycle = false
        else this.isShowCycle = true
      },
      //监听播放方式变化
      typeChange(e) {
        console.log(e)
        if (e == '设备播放') {
          this.isShowDevice = true
          this.isShowArea = false
        } else {
          this.isShowArea = true
          this.isShowDevice = false
        }
      },
      //试听
      showAudioModal(list) {
        let fileUrl = list.fileUrl
        this.$notification.open({
          key: audioKey,
          placement: 'bottomRight',
          description: (
            <a-row>
              <audio src={fileUrl} controls="controls" autoplay="autoplay" />
            </a-row>
          ),
          duration: null,
          style: { background: 'rgba(255,255,255,0.6)' }
        })
      },

      //单音频播放时选中设备
      clickOnDevice(index) {
        this.deviceList[index].status = !this.deviceList[index].status
      },
      //打开单音频播放弹窗
      showPlayModal(list) {
        this.playLists = [list]
        this.playModal.visible = true
      },
      showMultyPlayModal() {
        if (this.playLists && this.playLists.length === 0) {
          this.$message.warning('请选择需要播放的音频文件')
          return false
        }
        console.log('this.playLists', this.playLists)
        this.getData()
        this.resetForm()
        this.multyPlayModal.visible = true
      },

      //音频列表-删除
      deleteItem(id) {
        //   this.$api.deleteResource({ id }).then(res => {
        this.$api.deleteAudio({ id: id }).then((res) => {
          if (res.code === 200) {
            this.queryResourceList()
          }
        })
      },

      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys
        let allRows = this.cachePlainOptions
        let curRows = allRows.filter((item) => {
          return selectedRowKeys.includes(item.id)
        })
        let filtered = curRows.reduce((total, next) => {
          let has = total.some((f) => {
            return f.id === next.id
          })
          return has ? total : total.concat([next])
        }, [])
        this.playLists = filtered
      },

      //取消多音频播放弹窗
      cancelSelect() {
        this.multyPlayModal.visible = false
        this.selectedRowKeys = []
        this.playLists = []
      },
      //取消单音频播放弹窗
      cancelPlay() {
        this.playModal.visible = false
        this.playLists = []
      },

      deleteSelect(id) {
        this.selectedRowKeys = this.selectedRowKeys.filter((item) => item != id)
        this.playLists = this.playLists.filter((item) => item.id != id)
      },
      //上传文件
      getFile(result) {
        console.log(result)
        this.queryResourceList()
      },
      //单音频播放
      play(list) {
        this.playLists = [list]
        this.endTime = 0
        if (this.playStatus === 1) {
          this.stopAudioBroadcast(1)
        } else {
          this.playAudioFiles()
        }
      },
      //计算总时间
      calcTotalTimeLength() {
        let total = 0
        this.playLists.forEach((item) => {
          total += item.timeLength
        })
        return total
      },
      //播放文件
      playAudioFiles() {
        let totalTimeLength = this.calcTotalTimeLength()
        let params = {
          audioFileIds: this.playLists.map((item) => item.id).join(','),
          playDuration: this.endTime ? this.endTime * 60 : totalTimeLength
        }
        this.$api.playAudioFiles(params).then((res) => {
          if (res.code === 200) {
            this.multyPlayModal.visible = false
            //   this.tasker()
            this.totalTimeLength = this.endTime ? this.endTime * 60 : totalTimeLength
            this.selectedRowKeys = []
            this.playLists = []
          }
        })
      },
      //单音频播放
      playOnceAudio() {
        if (this.playDataSource && this.playDataSource.length === 4) {
          this.$message.error('当前播放数量达到上限')
          return
        }
        let result = []
        this.deviceList.forEach((v) => {
          if (v.status == true) {
            result.push(v.id)
          }
        })
        if (result && result.length == 0) {
          this.$message.error('请选择设备播放')
          return
        }
        let totalTimeLength = this.calcTotalTimeLength()
        let date = new Date()
        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

        let params = {
          audioIds: this.playLists.map((item) => item.id),
          duration: totalTimeLength,
          broadcastIds: result,
          startTime: `${Y}${M}${D}${h}${m}${s}`,
          name: `${Y}${M}${D}${h}${m}${s}`,
          times: 1,
          type: 2,
          timedFlag: 0,
          dayCycleFlag: 0
        }
        this.$api.playAudio(params).then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.playModal.visible = false
            //   this.tasker()
            this.totalTimeLength = totalTimeLength
            this.selectedRowKeys = []
            this.playLists = []

            //   this.getVoiceTaskList();
            this.refresh()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      //多音频播放
      playAudio() {
        if (this.playDataSource && this.playDataSource.length === 4) {
          this.$message.error('当前播放数量达到上限')
          return
        }
        // if (this.playNumber <= 0 && this.isShowCycle) {
        //   this.$message.error('循环次数不能小于1')
        //   return
        // }
        if (!this.taskName) {
          this.$message.error('请输入任务名称')
          return
        }
        if (!this.playType) {
          this.$message.error('请选择播放方式')
          return
        }
        if (this.device && this.device.length == 0 && this.isShowDevice) {
          this.$message.error('请选择播放设备')
          return
        }
        if (this.area && this.area.length == 0 && this.isShowArea) {
          this.$message.error('请选择播放区域')
          return
        }
        if (!this.timerPlay) {
          this.$message.error('请选择是否定时播放')
          return
        }
        // 循环播放
        if (this.isShowCycle) {
          // 不是定时播放
          if (this.timerPlay === '0') {
            // 播放次数
            if (!this.playCount) {
              this.$message.error('请输入播放次数')
              return
            }
          }
          // 是定时播放
          else if (this.timerPlay === '1') {
            if (!this.dailyCycle) {
              this.$message.error('请选择是否每日循环')
              return
            }
            if (!this.timerStartDate) {
              this.$message.error('请选择开始日期')
              return
            }
            if (!this.timerStartTime) {
              this.$message.error('请选择开始时间')
              return
            }

            if (this.dailyCycle === '1' && !this.timerEndTime) {
              this.$message.error('请选择结束时间')
              return
            }
          }
        }
        // 单次播放
        else {
          // 是定时播放
          if (this.timerPlay === '1') {
            if (!this.timerStartDate) {
              this.$message.error('请选择开始日期')
              return
            }
            if (!this.timerStartTime) {
              this.$message.error('请选择开始时间')
              return
            }
          }
        }

        let totalTimeLength = this.calcTotalTimeLength()
        let params = {
          audioIds: this.playLists.map((item) => item.id),
          duration: totalTimeLength,
          //   minutes: this.endTime ? this.endTime : totalTimeLength,
          name: this.taskName,
          type: 2
        }

        // 广播区域ID集合
        if (this.isShowArea) params.areaIds = this.area
        // 广播设备ID集合
        if (this.isShowDevice) params.broadcastIds = this.device
        // 是否定时播放
        params.timedFlag = Number(this.timerPlay)

        // 循环播放
        if (this.isShowCycle) {
          // 是定时播放
          if (this.timerPlay === '1') {
            // 开始日期
            // params.startDate = moment(this.timerStartDate).format('YYYY-MM-DD' )
            // 开始时间
            params.startTime = `${moment(this.timerStartDate).format('YYYY-MM-DD')} ${moment(this.timerStartTime).format('HH:mm')}:00`
            // 结束时间
            params.endTime = `${moment(this.timerStartDate).format('YYYY-MM-DD')} ${moment(this.timerEndTime).format('HH:mm')}:00`
            // 每日循环
            params.dayCycleFlag = this.dailyCycle
          } else {
            params.times = Number(this.playCount)
            params.dayCycleFlag = 0
          }
        }
        // 单次播放
        else {
          // 是定时播放
          if (this.timerPlay === '1') {
            // 开始日期
            // params.startDate = moment(this.timerStartDate).format('YYYY-MM-DD')
            // 开始时间
            params.startTime = `${moment(this.timerStartDate).format('YYYY-MM-DD')} ${moment(this.timerStartTime).format('HH:mm')}:00`
            // 每日循环
          } else {
            params.dayCycleFlag = '0'
          }
        }

        // 请求
        this.$api.playAudio(params).then((res) => {
          if (res.code === 200) {
            this.$message.success('播放成功')
            this.multyPlayModal.visible = false
            //   this.tasker()
            // this.totalTimeLength = this.endTime ? this.endTime * 60 : totalTimeLength
            this.selectedRowKeys = []
            this.playLists = []
            //   this.getVoiceTaskList();
            this.refresh()
            this.isShowCycle = false
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      //停止播放
      stopAudioBroadcast(type) {
        this.$api.stopAudioBroadcast().then((res) => {
          if (res.code === 200) {
            this.totalTimeLength = 0
            clearInterval(this.timer)
            this.timer = null
            if (type == 1) {
              this.playAudioFiles()
            }
          }
        })
      },
      //拖动中与拖动结束
      getdata(evt) {
        console.log(evt.draggedContext.element.id)
      },
      datadragEnd(evt) {
        console.log('拖动前的索引 :' + evt.oldIndex)
        console.log('拖动后的索引 :' + evt.newIndex)

        console.log(this.playLists)
        //   console.log(this.tags);
      },
      //分页相关

      onCurrentChange(page, pageSize) {
        this.current = page
        this.size = pageSize
        this.getData()
      },
      onShowSizeChange(current, size) {
        this.current = current
        this.size = size
        this.getData()
      }
    }
  }
</script>
<style lang="scss" scoped>
  /deep/.ant-select-selection--multiple {
    height: auto !important;
    margin-bottom: 10px;
  }
  // 必填星号
  .redstar {
    position: relative;
    box-sizing: border-box;
    padding-right: 10px;

    &:after {
      display: block;
      content: '*';
      position: absolute;
      width: 7px;
      height: 14px;
      color: #f5222d;
      font-size: 20px;
      top: 5px;
      left: -5px;
    }
  }
  .dataTabble {
    width: 100%;
    border: 1px solid #1c3d6c;
    line-height: 2.2;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    clear: both;
    display: block;
    text-align: center;
    thead {
      width: 100%;
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      background-color: #1c3d6c;
    }
    tr {
      width: 100%;
      border: 1px solid #1c3d6c;
      td {
        width: 30%;
      }
    }

    tr :hover {
      background-color: #1c3d6c;
    }
  }

  .clickOK {
    position: absolute;
    top: 0;
    display: inline-block;
    height: 145px;
    width: calc(100% - 16px);
    box-sizing: border-box;
    padding-top: 50px;
    line-height: 24px;
    font-size: 13px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    color: #ff9933;
    user-select: none;
  }
</style>
