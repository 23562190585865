<template>
  <a-upload
    name="file"
    :beforeUpload="beforeUploadFiles"
    :fileList="fileList"
    :listType="node.type == 3 ? 'picture-card' : 'text'"
    :accept="accept.join(',')"
    @change="uploadFile"
  >
    <!-- nodes.Type 1=> 文字  2=> 按钮  3=> 卡片 -->
    <a v-if="node.type == 1">{{ node.text }}</a>
    <a-button
      :disabled="uploadLoadding"
      :loading="uploadLoadding"
      :size="node.size || 'default'"
      type="primary"
      v-else-if="node.type == 2"
    >
      <a-icon v-if="node.icon && !uploadLoadding" :type="node.icon" />
      {{
      node.text
      }}
    </a-button>
    <div v-else>
      <template v-if="single">
        <template v-if="url">
          <img style="width: 80px; height: 80px" :src="url" alt="avatar" />
        </template>
        <template v-else>
          <a-icon type="plus" />
          <div class="ant-upload-text">{{ node.text }}</div>
        </template>
      </template>
      <template v-else>
        <a-icon type="plus" />
        <div class="ant-upload-text">{{ node.text }}</div>
      </template>
    </div>
  </a-upload>
</template>

<script>
export default {
  data() {
    return {
      file: {},
      fileList: [],
      FileArr: [],
      uploadLoadding: false,
      duration: 0
    };
  },
  props: ["node", "accept", "uploadUp", "loadData", "single", "url"], //uploadUp 判断上传多张照片数据
  created() { },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    },
    loadDatas() {
      return this.loadData;
    },
  },

  methods: {
    beforeUploadFiles(file) {
      this.file = file;
      return false;
    },
    getErrorMessage() {
      let message = "请上传";
      if (this.accept.length === 1) {
        message += this.accept[0].split(".")[1] + "格式文件";
      } else {
        this.accept.forEach((item) => {
          message += item.split(".")[1] + "、";
        });
        message += "等格式文件";
      }
      return message;
    },
    //文件格式判断
    isAcceptFileType() {
      let fileType = this.file.name
        .substring(this.file.name.lastIndexOf("."))
        .toLocaleLowerCase();
      if (!this.accept.includes(fileType)) {
        this.uploadLoadding = false;
        this.$message.warning(this.getErrorMessage());
        return false;
      }
      return true;
    },
    //上传文件到服务器
    uploadFile() {
      //文件格式判断
      let fileType = this.file.name
        .substring(this.file.name.lastIndexOf("."))
        .toLocaleLowerCase();
      if (!this.accept.includes(fileType)) {
        this.uploadLoadding = false;
        this.$message.warning(this.getErrorMessage());
        return false;
      }
      let url = URL.createObjectURL(this.file);
      let audioElement = new Audio(url);

      this.duration = parseInt(audioElement.duration);
      audioElement.addEventListener("loadedmetadata",
        () => {
          //   this.duration = parseInt(audioElement.duration); //时长为秒，取整          
          //   console.log(this.duration);
          //文件上传对象
          let formData = new FormData();
          formData.append("audioFile", this.file);
          formData.append('duration', parseInt(audioElement.duration))
          this.uploadLoadding = true;
          this.$api
            .insertResource(formData)
            .then((res) => {
              console.log('res', res);
              if (res.code === 200) {
                this.$message.success('上传成功')
                this.$emit("func", 'success');
              }
              else this.$message.error(res.msg)
              this.uploadLoadding = false;
            })
            .catch(() => {
              this.uploadLoadding = false;
            });
        });

    },
    //删除
  },
};
</script>