<!-- 计时器 -->
<template>
  <div class="timer">{{getTime(getSecond)}}</div>
</template>

<script>
export default {
  props: {
    second: {
      type: String,
      default: null
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      getSecond: 0,
      timer: null
    }
  },
  created() {
    if (this.second && this.type === 1) {
      this.getSecond = Number(this.second)
      this.timer = setInterval(this.aTimer, 1000)
    }
    if (this.second && this.type === 2) {
      this.getSecond = Number(this.second)
      this.timer = setInterval(this.mTimer, 1000)
    }

  },
  mounted() {

  },
  watch: {
    second: {
      handler(val) {
        console.log('val', val);
        this.getSecond = Number(val);
        if (this.type === 1) {
          this.timer = setInterval(this.aTimer, 1000)
        }
        else if (this.type == 2) {

          this.timer = setInterval(this.mTimer, 1000)
        }
      }
    }
  },
  methods: {
    aTimer() {
      this.getSecond++;
    },
    mTimer() {
      if (this.getSecond >= 1) {
        this.getSecond--;
      }
      else {
        this.$emit('changeToZero', 1)
        clearInterval(this.timer)
      }
    },

    getTime(value) {
      let secondTime = parseInt(value);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      let result = "" + parseInt(secondTime) + "秒";

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    }
  },
  beforeDestroy() {

    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.timer {
  width: 120px;
  display: inline-block;
}
</style>